exports.components = {
  "component---src-components-blog-page-blogdetails-index-js": () => import("./../../../src/components/blogPage/blogdetails/index.js" /* webpackChunkName: "component---src-components-blog-page-blogdetails-index-js" */),
  "component---src-components-blog-page-index-js": () => import("./../../../src/components/blogPage/index.js" /* webpackChunkName: "component---src-components-blog-page-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-bio-link-js": () => import("./../../../src/pages/bio-link.js" /* webpackChunkName: "component---src-pages-bio-link-js" */),
  "component---src-pages-certifications-js": () => import("./../../../src/pages/certifications.js" /* webpackChunkName: "component---src-pages-certifications-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-terms-n-conditions-js": () => import("./../../../src/pages/terms-n-conditions.js" /* webpackChunkName: "component---src-pages-terms-n-conditions-js" */)
}

